import { Box, Typography, Stack, Container, Link } from '@mui/material';

export const Footer = () => {
    return (
        <Box
            component="footer"
            sx={{
                py: 3,
                mt: 'auto',
                backgroundColor: 'background.paper',
                borderTop: '1px solid',
                borderColor: 'divider',
            }}
        >
            <Container>
                <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Link
                        href="https://koiasoft.no"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            '&:hover': {
                                opacity: 0.8
                            }
                        }}
                    >
                        <Box
                            component="img"
                            src="/assets/Logotype_KOIA_full_black.svg"
                            alt="KOIA"
                            sx={{
                                height: 20,
                                opacity: 0.7
                            }}
                        />
                    </Link>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="body2" color="text.secondary">
                            EF Rådgiver - Versjon 1.0 -
                        </Typography>
                        <Link
                            href="https://koiasoft.no"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                                color: 'text.secondary',
                                textDecoration: 'none',
                                '&:hover': {
                                    textDecoration: 'underline'
                                }
                            }}
                        >
                            <Typography variant="body2" component="span">
                                Koiasoft.no ©
                            </Typography>
                        </Link>
                        <Typography variant="body2" color="text.secondary">
                            2025
                        </Typography>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    );
}; 