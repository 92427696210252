import { useState } from 'react';
import { 
    Card, 
    CardContent, 
    Typography, 
    Grid,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Box,
    Chip
} from '@mui/material';
import { LikviditetsberegningBank, ColorType } from '../types';

interface KALPSummaryProps {
    liquidityCalculation: { [key: string]: LikviditetsberegningBank };
}

const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('no-NO', { 
        style: 'currency', 
        currency: 'NOK',
        maximumFractionDigits: 0 
    }).format(amount);
};

const getColorStyle = (color: ColorType) => {
    switch (color) {
        case 'red':
            return { bgcolor: '#d32f2f', color: 'white' };
        case 'green':
            return { bgcolor: '#2e7d32', color: 'white' };
        case 'yellow':
            return { bgcolor: '#ed6c02', color: 'white' };
        default:
            return { bgcolor: '#757575', color: 'white' };
    }
};

export const KALPSummary = ({ liquidityCalculation }: KALPSummaryProps) => {
    const banks = Object.keys(liquidityCalculation);
    const [selectedBank, setSelectedBank] = useState(banks[0] || '');

    if (!selectedBank || !liquidityCalculation[selectedBank]) return null;

    const { Resultat, Inntekter, Kostnader } = liquidityCalculation[selectedBank];

    return (
        <Card sx={{ mb: 2 }}>
            <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6" sx={{ mr: 2 }}>Likviditetsberegning</Typography>
                    <FormControl size="small" sx={{ minWidth: 200 }}>
                        <InputLabel id="bank-select-label">Velg bank</InputLabel>
                        <Select
                            labelId="bank-select-label"
                            value={selectedBank}
                            label="Velg bank"
                            onChange={(e) => setSelectedBank(e.target.value)}
                        >
                            {banks.map(bank => (
                                <MenuItem key={bank} value={bank}>{bank}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography><strong>Husholdningsutgifter:</strong></Typography>
                                <Chip 
                                    label={formatCurrency(Number(Kostnader.Husholdningsutgifter.value))}
                                    sx={{ ...getColorStyle(Kostnader.Husholdningsutgifter.color) }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography><strong>Boutgifter:</strong></Typography>
                                <Chip 
                                    label={formatCurrency(Number(Kostnader.boutgifter.value))}
                                    sx={{ ...getColorStyle(Kostnader.boutgifter.color) }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography><strong>Transportutgifter:</strong></Typography>
                                <Chip 
                                    label={formatCurrency(Number(Kostnader.transportutgifter.value))}
                                    sx={{ ...getColorStyle(Kostnader.transportutgifter.color) }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography><strong>Telefonutgifter:</strong></Typography>
                                <Chip 
                                    label={formatCurrency(Number(Kostnader.Telefonutgifter.value))}
                                    sx={{ ...getColorStyle(Kostnader.Telefonutgifter.color) }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography><strong>Total inntekt (inkl. leie):</strong></Typography>
                                <Chip 
                                    label={formatCurrency(Number(Inntekter['Samlet inntekt inkl. leieinntekter'].value))}
                                    sx={{ ...getColorStyle(Inntekter['Samlet inntekt inkl. leieinntekter'].color) }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography><strong>Netto inntekt etter stress:</strong></Typography>
                                <Chip 
                                    label={formatCurrency(Number(Inntekter['Nettoinntekt etter rentestress'].value))}
                                    sx={{ ...getColorStyle(Inntekter['Nettoinntekt etter rentestress'].color) }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography><strong>Likviditetsindikator:</strong></Typography>
                                <Chip 
                                    label={Number(Resultat.Likviditetsindikator.value).toFixed(2)}
                                    sx={{ ...getColorStyle(Resultat.Likviditetsindikator.color) }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography><strong>Overskuddslikviditet:</strong></Typography>
                                <Chip 
                                    label={formatCurrency(Number(Resultat.Overskuddslikviditet.value))}
                                    sx={{ ...getColorStyle(Resultat.Overskuddslikviditet.color) }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}; 