import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { Container } from '../container';
import { Header } from '../header';

interface LayoutProps {
    children: ReactNode;
}

export function Layout({ children }: LayoutProps) {
    const location = useLocation();
    const isRadgiverRoute = location.pathname.toLowerCase().includes('/efr');

    return (
        <>
            {!isRadgiverRoute && <Header />}
            <Container>{children}</Container>
        </>
    );
}
