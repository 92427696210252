import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';

interface BankLogoProps {
    bankName: string;
    size?: number;
}

const bankToImageMap: Record<string, string> = {
    'Balansebank': 'newBalance_bank.png',
    'Bank Norwegian': 'spare-bank.png',
    'Bluestep': 'bluestep_bigtext.png',
    'BN Bank': 'bn-bank.png',
    'Instabank': 'insta-bank.png',
    'Kraft Bank': 'kraft-bank.png',
    'Lea Bank': 'spare-bank.png',
    'Nordax': 'nordax-bank.png',
    'Ressurs Bank': 'spare-bank.png',
    'Skagerrak Sparebank': 'skagerrak-sparebank (1).png',
    'Skue Sparebank': 'skue-bank.png',
    'SMN': 'spare-bank.png',
    'Svea Finans': 'Svea.png'
};

export const BankLogo = ({ bankName, size = 40 }: BankLogoProps) => {
    const [imageError, setImageError] = useState(false);
    const imageName = bankToImageMap[bankName];
    
    if (!imageName || imageError) {
        return <Typography>{bankName}</Typography>;
    }

    return (
        <Box 
            component="img"
            src={`/assets/${imageName}`}
            alt={`${bankName} logo`}
            onError={() => setImageError(true)}
            sx={{
                width: size,
                height: 'auto',
                objectFit: 'contain',
                maxHeight: size,
                display: 'block'
            }}
        />
    );
}; 