import React, { useState } from 'react';
import { 
    Card, 
    CardContent, 
    Typography, 
    Grid, 
    Chip, 
    Box, 
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import { GjeldsGradBank, ColorType } from '../types';

interface GjeldsGradSummaryProps {
    gjeldsgrad: GjeldsGradBank;
}

const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('no-NO', { 
        style: 'currency', 
        currency: 'NOK',
        maximumFractionDigits: 0 
    }).format(amount);
};

const getColorStyle = (color: ColorType) => {
    switch (color) {
        case 'red':
            return { bgcolor: '#d32f2f', color: 'white' };
        case 'green':
            return { bgcolor: '#2e7d32', color: 'white' };
        case 'yellow':
            return { bgcolor: '#ed6c02', color: 'white' };
        default:
            return { bgcolor: '#757575', color: 'white' };
    }
};

export const GjeldsGradSummary = ({ gjeldsgrad }: GjeldsGradSummaryProps) => {
    const banks = Object.keys(gjeldsgrad);
    const [selectedBank, setSelectedBank] = useState(banks[0] || '');

    if (!selectedBank || !gjeldsgrad[selectedBank]) return null;

    const bankData = gjeldsgrad[selectedBank];

    return (
        <Card sx={{ mb: 2 }}>
            <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6" sx={{ mr: 2 }}>Gjeldsgrad</Typography>
                    <FormControl size="small" sx={{ minWidth: 200 }}>
                        <InputLabel id="bank-select-label">Velg bank</InputLabel>
                        <Select
                            labelId="bank-select-label"
                            value={selectedBank}
                            label="Velg bank"
                            onChange={(e) => setSelectedBank(e.target.value)}
                        >
                            {banks.map(bank => (
                                <MenuItem key={bank} value={bank}>{bank}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Box>
                                <Typography gutterBottom><strong>{bankData['Lån som beholdes'].caption}:</strong></Typography>
                                <Tooltip title={bankData['Lån som beholdes'].caption} arrow>
                                    <Chip 
                                        label={formatCurrency(Number(bankData['Lån som beholdes'].value))}
                                        sx={{ ...getColorStyle(bankData['Lån som beholdes'].color) }}
                                    />
                                </Tooltip>
                            </Box>
                            <Box>
                                <Typography gutterBottom><strong>{bankData['Ny langsiktig gjeld'].caption}:</strong></Typography>
                                <Tooltip title={bankData['Ny langsiktig gjeld'].caption} arrow>
                                    <Chip 
                                        label={formatCurrency(Number(bankData['Ny langsiktig gjeld'].value))}
                                        sx={{ ...getColorStyle(bankData['Ny langsiktig gjeld'].color) }}
                                    />
                                </Tooltip>
                            </Box>
                            <Box>
                                <Typography gutterBottom><strong>{bankData['Årsinntekt'].caption}:</strong></Typography>
                                <Tooltip title={bankData['Årsinntekt'].caption} arrow>
                                    <Chip 
                                        label={formatCurrency(Number(bankData['Årsinntekt'].value))}
                                        sx={{ ...getColorStyle(bankData['Årsinntekt'].color) }}
                                    />
                                </Tooltip>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Box>
                                <Typography gutterBottom><strong>{bankData['Brutto leieinntekter'].caption}:</strong></Typography>
                                <Tooltip title={bankData['Brutto leieinntekter'].caption} arrow>
                                    <Chip 
                                        label={formatCurrency(Number(bankData['Brutto leieinntekter'].value))}
                                        sx={{ ...getColorStyle(bankData['Brutto leieinntekter'].color) }}
                                    />
                                </Tooltip>
                            </Box>
                            <Box>
                                <Typography gutterBottom><strong>{bankData['Samlet inntekt'].caption}:</strong></Typography>
                                <Tooltip title={bankData['Samlet inntekt'].caption} arrow>
                                    <Chip 
                                        label={formatCurrency(Number(bankData['Samlet inntekt'].value))}
                                        sx={{ ...getColorStyle(bankData['Samlet inntekt'].color) }}
                                    />
                                </Tooltip>
                            </Box>
                            <Box>
                                <Typography gutterBottom><strong>{bankData['Gjeldsgrad'].caption}:</strong></Typography>
                                <Tooltip title={bankData['Gjeldsgrad'].caption} arrow>
                                    <Chip 
                                        label={Number(bankData['Gjeldsgrad'].value).toFixed(2)}
                                        sx={{ ...getColorStyle(bankData['Gjeldsgrad'].color) }}
                                    />
                                </Tooltip>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}; 