import { useMutation } from 'react-query';
import { getAppEnvValue } from '../../services/get-app-env-value';

export const useNordaxActions = () => {
    const baseUrl = getAppEnvValue('REACT_APP_ONESCORE_API_URL') || 'http://localhost:3000';

    const acceptOffer = useMutation(async ({ uuid }: { uuid: string }) => {
        const response = await fetch(`${baseUrl}/loans/NORDAX/${uuid}/accept`, {
            method: 'POST',
            body: JSON.stringify({
                loanAmount: 1, // placeholder value
                repaymentPeriod: 1, // placeholder value
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
    });

    const rejectOffer = useMutation(async ({ uuid }: { uuid: string }) => {
        const response = await fetch(`${baseUrl}/loans/NORDAX/${uuid}/reject`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
    });

    return {
        acceptOffer,
        rejectOffer,
    };
}; 