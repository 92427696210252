import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { ProcessErrorCode } from '../routes/error/types';
import { DocumentType } from '../routes/finance-data-upload/components/document-type-select/document-type-select';
import { getAppEnvValue } from '../services/get-app-env-value';
import { LoginFlow, SendingFlow } from '../utils/types';

export type ProcessStatus =
    | 'Opened'
    | 'Created'
    | 'Closed'
    | 'NotFound'
    | 'Unknown'
    | 'Finished'
    | 'Error';

class Eindomfinans {
    protected client: AxiosInstance;

    constructor(requestConfig: AxiosRequestConfig) {
        this.client = axios.create(requestConfig);
    }

    public checkRequestKey = (params: { requestKey: string }) =>
        this.client
            .get<{
                state: ProcessStatus;
                scope: string;
            }>(`/request-keys/${params.requestKey}`)
            .then((response) => response.data);

    public forwardDocuments = (params: {
        data: {
            processId: string;
            requestKey: string;
        };
    }) =>
        this.client
            .post(`/forward-documents`, params.data)
            .then((response) => response.data);

    public executeProcess = (params: {
        data: {
            gjeldsConsent: boolean;
            sendingFlow: SendingFlow;
            destinationKey: string;
            loginFlow: LoginFlow;
            userData: {
                personNumber: string;
                phoneNumber?: string;
            };
        };
    }) =>
        this.client
            .post<{ processId: string }>(`/finance-data-process`, params.data)
            .then((response) => response.data.processId);

    public checkProcessStatus = (params: { processId: string }) =>
        this.client
            .get<{
                state: ProcessStatus;
                errorCode?: ProcessErrorCode;
            }>(`/finance-data-process/${params.processId}`)
            .then((response) => {
                return response.data;
            });

    public sendMail = (params: {
        data: {
            processId: string;
            destination: string;
        };
    }) =>
        this.client
            .post<'Email sent' | 'Cannot send email'>(`/send-mail`, params.data)
            .then((response) => response.data);

    public authUrl = (params: {
        data: {
            redirectUrl: string;
        };
    }) =>
        this.client
            .post<{
                url: string;
                internalSessionId: string;
            }>('/auth-url', params.data)
            .then((response) => response.data);

    public authVerify = (params: {
        data: {
            signicatSessionId: string;
            internalSessionId: string;
        };
    }) =>
        this.client
            .post<{
                success: boolean;
                ssn: string;
            }>(`/auth-verify`, params.data)
            .then((response) => response.data);

    public uploadSkatt = (params: {
        data: {
            documentDescription: {
                documentType: string;
                processId: string;
            };
            document: File;
        };
    }) => this.client.post(`/skatt/upload`, params.data);

    public checkProcessExists = (params: { personNumber: string }) =>
        this.client
            .get<
                | {
                      exists: false;
                      data: null;
                  }
                | {
                      exists: true;
                      data: {
                          processId: string;
                          personNumber: string;
                          gjeldsConsent: boolean;
                          loginFlow: LoginFlow;
                          sendingFlow: SendingFlow;
                          destinationKey: string;
                      };
                  }
            >(`finance-data-process/personNumber/${params.personNumber}`)
            .then((response) => response.data);

    public checkUploadedFiles = (params: { processID: string }) =>
        this.client
            .get<{
                success: boolean;
                documents: { documentType: DocumentType; s3Key: string }[];
            }>(`skatt/${params.processID}/files`)
            .then((response) => response.data);
}

export const EindomfinansAPI = new Eindomfinans({
    baseURL: `${getAppEnvValue('REACT_APP_ONESCORE_API_URL')}`,
});
