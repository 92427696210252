type BrowserStorageKey = (typeof browserStorageKeys)[number];

const browserStorageMethods = <T extends string>(
    key: BrowserStorageKey,
    storageType: 'session' | 'local',
) => {
    const storage = storageType === 'session' ? sessionStorage : localStorage;

    return {
        get: () => storage.getItem(key) as T | null,
        set: (value: T) => storage.setItem(key, value),
        clear: () => storage.removeItem(key),
    };
};

/**
 * Pre-defined browser storage keys.
 */
const browserStorageKeys = ['INTERNAL_SESSION_ID'] as const;

/**
 * Util function to access browser storage with pre-defined keys.
 */
export const browserStorage = {
    INTERNAL_SESSION_ID: browserStorageMethods<string>(
        'INTERNAL_SESSION_ID',
        'local',
    ),
};
