import { useMutation } from 'react-query';
import { getAppEnvValue } from '../../services/get-app-env-value';

interface AcceptOfferPayload {
    loanAmount: number;
    repaymentPeriod: number;
}

export const useBankNorwegianActions = () => {
    const baseUrl = getAppEnvValue('REACT_APP_ONESCORE_API_URL') || 'http://localhost:3000';

    const acceptOffer = useMutation(async ({ 
        applicationId, 
        payload 
    }: { 
        applicationId: string; 
        payload: AcceptOfferPayload 
    }) => {
        const response = await fetch(`${baseUrl}/loans/BANK_NORWEGIAN/${applicationId}/accept`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
    });

    const rejectOffer = useMutation(async ({ applicationId }: { applicationId: string }) => {
        const response = await fetch(`${baseUrl}/loans/BANK_NORWEGIAN/${applicationId}/reject`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
    });

    return {
        acceptOffer,
        rejectOffer,
    };
}; 