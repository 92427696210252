import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Box,
    Card,
    CardContent,
    Grid,
    TextField,
    Typography,
    Button,
    Snackbar,
    Alert,
} from '@mui/material';
import { routeParamsRecord } from '../../../routes/routes-map';
import { getAppEnvValue } from '../../../services/get-app-env-value';

interface FeedbackFormsProps {
    inputData: any;
}

interface FeedbackDto {
    data: object;
    calculationsId: string;
    comment: string;
    url: string;
}

export const FeedbackForms = ({ inputData }: FeedbackFormsProps) => {
    const params = useParams();
    const processId = params[routeParamsRecord.processId] || '';
    const [modelFeedback, setModelFeedback] = useState('');
    const [bankFeedback, setBankFeedback] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const sendFeedback = async (comment: string) => {
        const feedbackData: FeedbackDto = {
            data: inputData,
            calculationsId: processId,
            comment,
            url: window.location.href
        };

        try {
            setIsSubmitting(true);
            setError(null);
            
            const baseUrl = getAppEnvValue('REACT_APP_ONESCORE_API_URL') || 'http://localhost:3000';
            const response = await fetch(`${baseUrl}/loans/calculations/${processId}/feedback`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(feedbackData),
            });

            if (!response.ok) {
                throw new Error('Failed to submit feedback');
            }

            return true;
        } catch (err) {
            console.error('Error submitting feedback:', err);
            setError(err instanceof Error ? err.message : 'Failed to submit feedback');
            return false;
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleModelFeedbackSubmit = async () => {
        const success = await sendFeedback(modelFeedback);
        if (success) {
            setModelFeedback('');
            setShowSuccess(true);
        }
    };

    const handleBankFeedbackSubmit = async () => {
        const success = await sendFeedback(bankFeedback);
        if (success) {
            setBankFeedback('');
            setShowSuccess(true);
        }
    };

    const handleCloseSnackbar = () => {
        setShowSuccess(false);
        setError(null);
    };

    return (
        <Box sx={{ mt: 4 }}>
            <Grid container spacing={3}>
                {/* Model Feedback Form */}
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Kunne vi gjort noe bedre i denne saken?
                            </Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                                Hvis du har funnet noe hvor modellen har gjort en feil vil vi gjerne vite om det! F.eks. at en kommentar eller beregning har misforstått noe på saken.
                            </Typography>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                value={modelFeedback}
                                onChange={(e) => setModelFeedback(e.target.value)}
                                placeholder="Skriv din tilbakemelding her..."
                                sx={{ mb: 2 }}
                                disabled={isSubmitting}
                            />
                            <Button
                                variant="contained"
                                onClick={handleModelFeedbackSubmit}
                                disabled={!modelFeedback || isSubmitting}
                            >
                                {isSubmitting ? 'Sender...' : 'Send tilbakemelding'}
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Bank Feedback Form */}
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Overraskende feedback fra bank?
                            </Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                                Hvis du har noe nyttig input fra en bank vil vi gjerne vite det, f.eks at de vil ha en sak vi ikke trodde de ville ha, eller at de har spesialregler vi burde skrevet om.
                            </Typography>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                value={bankFeedback}
                                onChange={(e) => setBankFeedback(e.target.value)}
                                placeholder="Skriv din tilbakemelding her..."
                                sx={{ mb: 2 }}
                                disabled={isSubmitting}
                            />
                            <Button
                                variant="contained"
                                onClick={handleBankFeedbackSubmit}
                                disabled={!bankFeedback || isSubmitting}
                            >
                                {isSubmitting ? 'Sender...' : 'Send tilbakemelding'}
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {/* Success/Error Notifications */}
            <Snackbar
                open={showSuccess || !!error}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert 
                    onClose={handleCloseSnackbar} 
                    severity={error ? 'error' : 'success'}
                >
                    {error || 'Takk for tilbakemeldingen!'}
                </Alert>
            </Snackbar>
        </Box>
    );
}; 