interface Options {
    url?: string;
    queryKey: string;
}

/**
 * Get the value of the query key from the search params.
 * @param options - The options object.
 * @param options.url - The url to get the search params from. If not provided, the current url will be used.
 * @param options.queryKey - The key of the query to get the value from.
 * @returns The value of the query key from the search params.
 */
export const getSearchParamsFromUrl = (options: Options) => {
    /**
     * Destructure the options object.
     */
    const { url = window.location.href, queryKey } = options;

    /**
     * Get the search params from the url.
     */
    const searchParams = new URL(url).searchParams;

    /**
     * Get the value of the query key from the search params.
     */
    const value = searchParams.get(queryKey);

    /**
     * Return the value if it is defined, otherwise return undefined.
     */
    return value ? value : undefined;
};
