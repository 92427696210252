import { UseFormReturn, useWatch } from 'react-hook-form';

import { Button, Stack, styled } from '@mui/material';

const fileAcceptAttributes = ['application/pdf', '.pdf'].join(',');

const Input = styled('input')({
    display: 'none',
});

interface FileSelectProps {
    form: UseFormReturn<any, any>;
}

export const useFileSelect = ({ form }: FileSelectProps) => {
    const { control, register } = form;

    const selectedFile = useWatch({
        control,
        name: 'files',
    });

    const selectedFileName = selectedFile ? selectedFile[0].name : null;

    const FileSelect = () => (
        <label htmlFor="contained-button-file" style={{ position: 'relative' }}>
            <Input
                accept={fileAcceptAttributes}
                id="contained-button-file"
                type="file"
                {...register('files')}
            />
            <Stack spacing={1} alignItems="center">
                <Button
                    variant="outlined"
                    component="span"
                    sx={{
                        height: '100%',
                        maxHeight: '40px',
                        minWidth: '160px',
                        padding: '7px 10px',
                    }}
                >
                    Velg dokument
                </Button>
            </Stack>
        </label>
    );

    return {
        FileSelect,
        selectedFileName,
        selectedFile,
    };
};
