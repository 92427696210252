import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetEFR } from './useGetEFR';
import { 
    Typography,
    Grid,
    Card,
    CardContent,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    CircularProgress,
    Chip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { 
    BankTable,
    LoanSummaryTable,
    LTVSummary,
    GjeldsGradSummary,
    KALPSummary,
    FeedbackForms
} from './components';
import { EFRResponse, ColorType, BankDetails, NøkkeltallBank } from './types';
import { Footer } from './components/Footer';
import { routeParamsRecord } from '../routes-map';
import { BankNorwegianOffers } from './components/BankNorwegianOffers';
import { NordaxOffers } from './components/NordaxOffers';

const getUniqueComments = (comments: string[] | string) => {
    if (!comments) return '';
    
    // If comments is a string, convert to array by splitting on newlines
    const commentsArray = Array.isArray(comments) 
        ? comments 
        : comments.split('\n');

    // Filter unique comments and join back
    return commentsArray
        .filter(comment => comment.trim()) // Remove empty lines
        .filter((comment, index, self) => 
            self.indexOf(comment) === index // Keep only first occurrence
        )
        .join('\n');
};

const getColorStyle = (color: ColorType) => {
    switch (color) {
        case 'red':
            return { bgcolor: '#d32f2f', color: 'white' };
        case 'green':
            return { bgcolor: '#2e7d32', color: 'white' };
        case 'yellow':
            return { bgcolor: '#ed6c02', color: 'white' };
        default:
            return { bgcolor: '#757575', color: 'white' };
    }
};

export const RadgiverRoute = () => {
    const params = useParams();
    const processId = params[routeParamsRecord.processId] || '';
    const { data, bankNorwegianData, nordaxData, isLoading } = useGetEFR(processId);

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                <CircularProgress />
            </Box>
        );
    }

    if (!data) {
        return <Typography color="error">No data available</Typography>;
    }

    const efrData = data.data;

    const allBanks = {
        ...efrData['Aktuelle banker'].Boliglånsbanker,
        ...efrData['Aktuelle banker'].Spesiallånsbanker
    };

    const validBanks = Object.entries(allBanks)
        .filter(([_, details]: [string, BankDetails]) => Number(details.prob.value) > 50)
        .sort((a, b) => Number((a[1] as BankDetails).mndTerminbeløp.value) - Number((b[1] as BankDetails).mndTerminbeløp.value))
        .map(([bank]) => bank);

    const remainingBanks = Object.entries(allBanks)
        .filter(([_, details]: [string, BankDetails]) => Number(details.prob.value) > 0 && Number(details.prob.value) <= 50)
        .sort((a, b) => Number((b[1] as BankDetails).prob.value) - Number((a[1] as BankDetails).prob.value))
        .map(([bank]) => bank);

    const allValidBanks = [...validBanks, ...remainingBanks];

    return (
        <Box 
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                minHeight: '100vh',
                width: '100vw',
                position: 'relative',
                left: '50%',
                right: '50%',
                marginLeft: '-50vw',
                marginRight: '-50vw',
                bgcolor: 'background.default'
            }}
        >
            <Box 
                sx={{ 
                    maxWidth: '1600px',
                    width: '100%',
                    margin: '0 auto',
                    px: { xs: 2, sm: 3, md: 4 },
                    py: 4,
                    flex: '1 0 auto'
                }}
            >
                <Box my={4} sx={{ flex: '1 0 auto' }}>
                    {/* Header with Logo and Lånesak */}
                    <Box sx={{ mb: 4 }}>
                        <Card>
                            <CardContent sx={{ px: 4 }}>
                                <Grid container spacing={4} alignItems="center">
                                    <Grid item xs={12} md={3}>
                                        <Box 
                                            component="img"
                                            src="/logo.png"
                                            alt="Logo"
                                            sx={{ 
                                                height: 60,
                                                objectFit: 'contain'
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4.5}>
                                        <Typography><strong>Rådgiver:</strong> {efrData.Lånesak.Rådgiver}</Typography>
                                        <Typography><strong>Type lån:</strong> {efrData.Lånesak['Type lån']}</Typography>
                                        <Typography><strong>Lånebeløp:</strong> {efrData.Lånesak.Lånebeløp.toLocaleString('no-NO', { style: 'currency', currency: 'NOK', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4.5}>
                                        <Typography><strong>Formål:</strong> {efrData.Lånesak.Formål}</Typography>
                                        <Typography><strong>Prioritet:</strong> {efrData.Lånesak.Prioritet}</Typography>
                                        <Typography><strong>UID:</strong> {efrData.Lånesak.UID}</Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Box>

                    {/* Key Figures Summary */}
                    <Card sx={{ mb: 4 }}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>Nøkkeltall (beste alternativ)</Typography>
                            <Box sx={{ 
                                display: 'grid', 
                                gridTemplateColumns: 'repeat(4, 1fr)',
                                gap: 3,
                                mt: 2
                            }}>
                                {Object.entries(efrData['Nøkkeltall (beste alternativ)']).map(([key, figure]: [string, NøkkeltallBank]) => {
                                    const metricName = key.split(' ')[1];
                                    const formattedValue = metricName === 'LTV' 
                                        ? `${(figure.value * 100).toFixed(1)}%`
                                        : metricName === 'Likviditet'
                                            ? Number(figure.value).toLocaleString('no-NO')
                                            : Number(figure.value).toLocaleString('no-NO', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

                                    return (
                                        <Box key={key} sx={{ 
                                            display: 'flex', 
                                            flexDirection: 'column', 
                                            alignItems: 'center'
                                        }}>
                                            <Typography variant="subtitle2" gutterBottom>{metricName}</Typography>
                                            <Chip 
                                                label={`${formattedValue} (${figure.bank})`}
                                                sx={{ ...getColorStyle(figure.color), fontSize: '1rem', height: '32px', minWidth: '80px' }}
                                            />
                                        </Box>
                                    );
                                })}
                            </Box>
                        </CardContent>
                    </Card>

                    {/* Warnings */}
                    {efrData.Advarsler.Advarsler && (
                        <Card sx={{ mb: 4, bgcolor: '#fff3e0' }}>
                            <CardContent>
                                <Typography variant="h6" color="warning.main" gutterBottom>Advarsler</Typography>
                                <Typography>{efrData.Advarsler.Advarsler}</Typography>
                            </CardContent>
                        </Card>
                    )}

                     {/* General Comments */}
                     <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>Generelle kommentarer til saken</Typography>
                            <Typography 
                                variant="body1" 
                                component="pre" 
                                sx={{ whiteSpace: 'pre-wrap' }}
                            >
                                {getUniqueComments(efrData['Generelle kommentarer til saken']['Generelle kommentarer til saken'])}
                            </Typography>
                        </CardContent>
                    </Card>

                    {/* Summary Sections */}
                    <Accordion defaultExpanded sx={{ mb: 2 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">Detaljer</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <LTVSummary ltv_details={efrData.Detaljer['LTV / Belåningsgrad']} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <GjeldsGradSummary gjeldsgrad={efrData.Detaljer.Gjeldsgrad} />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    {/* Bank Comparison */}
                    <Accordion defaultExpanded sx={{ mb: 2 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">Aktuelle banker</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <BankTable 
                                banks={efrData['Aktuelle banker']}
                                validBanks={allValidBanks}
                                banksNotIncluded={efrData['Aktuelle banker']['Banker ikke inkludert']}
                            />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion sx={{ mb: 2 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">Saksnotat</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography 
                                variant="body1" 
                                component="pre" 
                                sx={{ whiteSpace: 'pre-wrap' }}
                            >
                                {efrData['Detaljer']['Saksbeskrivelse']['value']}
                                {'\n'}
                                {}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    {efrData['Generelle kommentarer til saken']['Advarsler'] && (
                        <Card sx={{ mb: 4, bgcolor: '#fff3e0' }}>
                            <CardContent>
                                <Typography variant="h6" color="warning.main" gutterBottom>Advarsler</Typography>
                                <Typography>{efrData['Generelle kommentarer til saken']['Advarsler']}</Typography>
                            </CardContent>
                        </Card>
                    )}

                    {/* Existing Loans */}
                    <Accordion sx={{ mb: 2 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">Eksisterende lån</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <LoanSummaryTable existingLoans={efrData['Eksisterende lån'] as any} />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion defaultExpanded sx={{ mb: 2 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">Likviditetsberegning</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <KALPSummary 
                                liquidityCalculation={efrData.Likviditetsberegning}
                            />
                        </AccordionDetails>
                    </Accordion>

                    {/* Feedback Forms */}
                    <FeedbackForms inputData={efrData['Input data']} />

                    {/* Bank Norwegian Offers */}
                    <BankNorwegianOffers 
                        data={bankNorwegianData ?? null}
                        onActionComplete={() => {
                            // Optionally refresh the data after an action is completed
                        }}
                    />

                    {/* Nordax Offers */}
                    <NordaxOffers 
                        data={nordaxData ?? null}
                        onActionComplete={() => {
                            // Optionally refresh the data after an action is completed
                        }}
                    />
                </Box>
                <Footer />
            </Box>
        </Box>
    );
}; 