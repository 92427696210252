import React from 'react';
import { 
    Paper, 
    Chip,
    Box,
    Tooltip,
    Grid,
    Typography
} from '@mui/material';
import { ValueColorCaption, ColorType } from '../types';

interface ExistingLoanData {
    'Antall eksisterende lån': ValueColorCaption;
    'Sum eksisterende lån': ValueColorCaption;
    'Hvorav \'dårlig\' gjeld': ValueColorCaption;
    'Lån som skal beholdes': ValueColorCaption;
    'Månedskostnad eksisterende lån': ValueColorCaption;
    'Månedskostnad eksisterende lån v/rentestress': ValueColorCaption;
    'Månedskostnad på lån som skal beholdes': ValueColorCaption;
    'Månedskostnad på lån som skal beholdes (stresset)': ValueColorCaption;
    'Antall inkassolån': ValueColorCaption;
    'Antall lån som er misligholdt': ValueColorCaption;
}

interface LoanSummaryTableProps {
    existingLoans: ExistingLoanData;
}

const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('no-NO', { 
        style: 'currency', 
        currency: 'NOK',
        maximumFractionDigits: 0 
    }).format(amount);
};

const getColorStyle = (color: ColorType) => {
    switch (color) {
        case 'red':
            return { bgcolor: '#d32f2f', color: 'white' };
        case 'green':
            return { bgcolor: '#2e7d32', color: 'white' };
        case 'yellow':
            return { bgcolor: '#ed6c02', color: 'white' };
        default:
            return { bgcolor: '#757575', color: 'white' };
    }
};

export const LoanSummaryTable = ({ existingLoans }: LoanSummaryTableProps) => {
    if (!existingLoans) return null;

    const rows = [
        { key: 'Antall eksisterende lån' as const, format: (value: number) => value.toString() },
        { key: 'Sum eksisterende lån' as const, format: formatCurrency },
        { key: 'Hvorav \'dårlig\' gjeld' as const, format: formatCurrency },
        { key: 'Lån som skal beholdes' as const, format: formatCurrency },
        { key: 'Månedskostnad eksisterende lån' as const, format: formatCurrency },
        { key: 'Månedskostnad eksisterende lån v/rentestress' as const, format: formatCurrency },
        { key: 'Månedskostnad på lån som skal beholdes' as const, format: formatCurrency },
        { key: 'Månedskostnad på lån som skal beholdes (stresset)' as const, format: formatCurrency },
        { key: 'Antall inkassolån' as const, format: (value: number) => value.toString() },
        { key: 'Antall lån som er misligholdt' as const, format: (value: number) => value.toString() }
    ];

    // Split rows into two columns
    const midPoint = Math.ceil(rows.length / 2);
    const leftColumnRows = rows.slice(0, midPoint);
    const rightColumnRows = rows.slice(midPoint);

    return (
        <Grid container spacing={3}>
            {[leftColumnRows, rightColumnRows].map((columnRows, columnIndex) => (
                <Grid item xs={12} md={6} key={columnIndex}>
                    <Paper sx={{ p: 2 }}>
                        {columnRows.map(({ key, format }) => (
                            <Box key={key} sx={{ 
                                display: 'flex', 
                                justifyContent: 'space-between', 
                                alignItems: 'center',
                                mb: 1.5
                            }}>
                                <Typography sx={{ mr: 2, flex: 1 }}>
                                    {existingLoans[key].caption}
                                </Typography>
                                <Tooltip title={existingLoans[key].caption} arrow>
                                    <Chip 
                                        label={format(Number(existingLoans[key].value))}
                                        sx={{ ...getColorStyle(existingLoans[key].color) }}
                                    />
                                </Tooltip>
                            </Box>
                        ))}
                    </Paper>
                </Grid>
            ))}
        </Grid>
    );
}; 