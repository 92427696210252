import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

import { Layout } from '../components/layout';
import { LoginRoute } from './authentication';
import { HomeRoute } from './home';
import { ROUTES } from './routes-map';

import { ErrorRoute } from './error';
import { FinanceDataInitRoute } from './finance-data-init';
import { FinanceDataSubmitRoute } from './finance-data-submit';
import { FinanceDataUploadRoute } from './finance-data-upload';
import { PreLoginRoute } from './pre-login';
import { VerificationRoute } from './verification';
import { RadgiverRoute } from './radgiver';

export function AppRoutes() {
    return (
        <BrowserRouter>
            <Layout>
                <Routes>
                    <Route path={ROUTES.HOME.path} element={<HomeRoute />} />
                    <Route
                        path={ROUTES.HOME_WITH_TARGET.path}
                        element={<HomeRoute />}
                    />
                    <Route
                        path={ROUTES.PRE_LOGIN.path}
                        element={<PreLoginRoute />}
                    />
                    <Route path={ROUTES.LOGIN.path} element={<LoginRoute />} />
                    <Route
                        path={ROUTES.VERIFICATION.path}
                        element={<VerificationRoute />}
                    />
                    <Route
                        path={ROUTES.FINANCE_DATA_INIT.path}
                        element={<FinanceDataInitRoute />}
                    />
                    <Route
                        path={ROUTES.FINANCE_DATA_UPLOAD.path}
                        element={<FinanceDataUploadRoute />}
                    />
                    <Route
                        path={ROUTES.FINANCE_DATA_SUBMIT.path}
                        element={<FinanceDataSubmitRoute />}
                    />
                    <Route
                        path={ROUTES.RADGIVER.path}
                        element={<RadgiverRoute />}
                    />
                    <Route path={ROUTES.ERROR.path} element={<ErrorRoute />} />
                    <Route
                        path={ROUTES.ERROR_WITH_TYPE.path}
                        element={<ErrorRoute />}
                    />
                </Routes>
            </Layout>
        </BrowserRouter>
    );
}
