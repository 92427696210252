import { useForm } from 'react-hook-form';

import { Box, Checkbox, FormControlLabel } from '@mui/material';

import { browserStorage } from '../../../../services/browser-storage';
import { useStore } from '../../../../store/store';
import { useAuthUrl } from '../../api';
import { FormButton } from '../form-button';
import S from './styles';

export const BankIDoginForm = () => {
    const form = useForm<{
        gjeldsConsent: boolean;
    }>();

    const authUrl = useAuthUrl();

    const { setGjeldsConsent } = useStore((state) => ({
        setGjeldsConsent: state.setGjeldsConsent,
    }));

    const onSubmit = form.handleSubmit(async (values) => {
        try {
            const response = await authUrl.mutateAsync({
                data: {
                    redirectUrl: `${window.location.origin}/verification`,
                },
            });

            browserStorage.INTERNAL_SESSION_ID.set(response.internalSessionId);
            setGjeldsConsent(values.gjeldsConsent);

            window.location.href = response.url;
        } catch (error) {
            console.error(error);
        }
    });

    return (
        <div>
            <S.Form onSubmit={onSubmit}>
                <FormControlLabel
                    label="Samtykke til deling av gjeldsinformasjon"
                    style={{ alignItems: '' }}
                    control={<Checkbox {...form.register('gjeldsConsent')} />}
                />
                <Box
                    style={{
                        fontSize: 14,
                        marginTop: -35,
                        paddingLeft: 30,
                    }}
                >
                    <p>
                        Jeg gir samtykke til at Eiendomsfinans AS, orgnr.
                        967692301 kan innhente mine gjeldsopplysninger fra
                        Gjeldsregisteret AS for å kunne gi privatøkonomisk
                        rådgivning.
                    </p>
                    <p>
                        Eiendomsfinans AS er behandlingsansvarlig for behandling
                        av personopplysninger i forbindelse med økonomisk
                        rådgivning, og som skal påse og påvise at opplysningene
                        behandles i samsvar med gjeldende personopplysningslov,
                        se personvernerklæring på
                        {` `}
                        <a
                            href="https://www.eiendomsfinans.no/personvern/"
                            target="_blank"
                        >
                            https://www.eiendomsfinans.no/personvern/
                        </a>
                        . Gjeldsopplysningene presenteres med samlet
                        innhold/felter og struktur i tråd med hvordan
                        opplysningene presenteres i Gjeldsregisteret sine
                        web-tjenester. Gjeldsopplysningene er levert av
                        Gjeldsregisteret AS. Oversikt over finansforetak som
                        leverer gjeldsopplysninger til Gjeldsregisteret AS
                        finner dere på{' '}
                        <a href="www.gjeldsregisteret.com" target="_blank">
                            gjeldsregisteret.com
                        </a>
                        .
                    </p>
                    <p>
                        Fullmakten er gyldig i 6 måneder. Samtykket ifm delte
                        gjeldsopplysninger kan når som helst trekkes hos{' '}
                        <a href="www.gjeldsregisteret.com" target="_blank">
                            www.gjeldsregisteret.com
                        </a>
                        .
                    </p>
                </Box>
                <FormButton
                    label="Fortsett"
                    type="submit"
                    disabled={authUrl.isLoading || authUrl.isSuccess}
                />
            </S.Form>
        </div>
    );
};
