import { useCallback, useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Button } from '../../components/button';
import { ErrorBox } from '../../components/error-box';
import { Loader } from '../../components/loader';
import { useCheckProcessExists } from '../../queries/use-check-process-exists';
import { useAuthVerify } from '../../queries/useAuthVerify';
import { browserStorage } from '../../services/browser-storage';
import { getSearchParamsFromUrl } from '../../services/get-search-params-from-url';
import { useStore } from '../../store/store';
import { ROUTES } from '../routes-map';

export const VerificationRoute = () => {
    const shouldVerify = useRef(true);

    const [state, setState] = useState<
        'initial' | 'loading' | 'success' | 'error'
    >('initial');

    const authVerify = useAuthVerify();
    const checkProcessExists = useCheckProcessExists();

    const navigate = useNavigate();

    const sessionId = getSearchParamsFromUrl({ queryKey: 'sessionId' });

    const { setUserData, setProcessExists } = useStore((state) => ({
        setUserData: state.setUserData,
        setProcessExists: state.setProcessExists,
    }));

    const handleVerify = useCallback(async () => {
        const internalSessionId = browserStorage.INTERNAL_SESSION_ID.get();

        if (!internalSessionId) {
            throw new Error('internalSessionId is missing');
        }

        if (!sessionId) {
            throw new Error('sessionId is missing');
        }

        try {
            setState('loading');
            shouldVerify.current = false;

            const response = await authVerify.mutateAsync({
                data: {
                    signicatSessionId: sessionId,
                    internalSessionId,
                },
            });

            setUserData({
                personNumber: response.ssn,
            });

            const processExists = await checkProcessExists.mutateAsync({
                personNumber: response.ssn,
            });

            if (processExists && processExists.data) {
                setProcessExists(processExists.data);
                navigate(
                    ROUTES.FINANCE_DATA_UPLOAD.buildPath({
                        processId: processExists.data.processId,
                    }),
                );
            } else {
                navigate(ROUTES.FINANCE_DATA_INIT.path);
            }
        } catch (error) {
            setState('error');
            console.error(error);
        }
    }, [authVerify, navigate, setUserData]);

    useEffect(() => {
        if (sessionId && state === 'initial' && shouldVerify.current) {
            handleVerify();
        }
    }, [sessionId, state, handleVerify]);

    if (state === 'error') {
        return (
            <ErrorBox>
                <a href="https://www.eiendomsfinans.no/">
                    <Button variant="primary">Avslutt</Button>
                </a>
            </ErrorBox>
        );
    }

    if (state === 'loading' || state === 'success') {
        return (
            <div style={{ textAlign: 'center' }}>
                Når du bekrefter påloggingsinformasjonen din, kan dette ta en
                stund, vennligst ikke lukk dette vinduet.
                <Loader />
            </div>
        );
    }

    return null;
};
