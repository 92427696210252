import React from 'react';
import { Card, CardContent, Typography, Grid, Chip, Box, Tooltip } from '@mui/material';
import { LTVDetails, ColorType } from '../types';

interface LTVSummaryProps {
    ltv_details: LTVDetails;
}

const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('no-NO', { 
        style: 'currency', 
        currency: 'NOK',
        maximumFractionDigits: 0 
    }).format(amount);
};

const formatPercentage = (value: number) => {
    return `${(value * 100).toFixed(2)}%`;
};

const getColorStyle = (color: ColorType) => {
    switch (color) {
        case 'red':
            return { bgcolor: '#d32f2f', color: 'white' };
        case 'green':
            return { bgcolor: '#2e7d32', color: 'white' };
        case 'yellow':
            return { bgcolor: '#ed6c02', color: 'white' };
        default:
            return { bgcolor: '#757575', color: 'white' };
    }
};

export const LTVSummary = ({ ltv_details }: LTVSummaryProps) => (
    <Card sx={{ mb: 2 }}>
        <CardContent>
            <Typography variant="h6" gutterBottom>LTV / Belåningsgrad</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Box>
                            <Typography gutterBottom><strong>{ltv_details['Nytt lån'].caption}:</strong></Typography>
                            <Tooltip title={ltv_details['Nytt lån'].caption} arrow>
                                <Chip 
                                    label={formatCurrency(Number(ltv_details['Nytt lån'].value))}
                                    sx={{ ...getColorStyle(ltv_details['Nytt lån'].color) }}
                                />
                            </Tooltip>
                        </Box>
                        <Box>
                            <Typography gutterBottom><strong>{ltv_details['Boliglån som ikke skal refinansieres'].caption}:</strong></Typography>
                            <Tooltip title={ltv_details['Boliglån som ikke skal refinansieres'].caption} arrow>
                                <Chip 
                                    label={formatCurrency(Number(ltv_details['Boliglån som ikke skal refinansieres'].value))}
                                    sx={{ ...getColorStyle(ltv_details['Boliglån som ikke skal refinansieres'].color) }}
                                />
                            </Tooltip>
                        </Box>
                        <Box>
                            <Typography gutterBottom><strong>{ltv_details['Realkausjon'].caption}:</strong></Typography>
                            <Tooltip title={ltv_details['Realkausjon'].caption} arrow>
                                <Chip 
                                    label={formatCurrency(Number(ltv_details['Realkausjon'].value))}
                                    sx={{ ...getColorStyle(ltv_details['Realkausjon'].color) }}
                                />
                            </Tooltip>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Box>
                            <Typography gutterBottom><strong>{ltv_details['Fellesgjeld'].caption}:</strong></Typography>
                            <Tooltip title={ltv_details['Fellesgjeld'].caption} arrow>
                                <Chip 
                                    label={formatCurrency(Number(ltv_details['Fellesgjeld'].value))}
                                    sx={{ ...getColorStyle(ltv_details['Fellesgjeld'].color) }}
                                />
                            </Tooltip>
                        </Box>
                        <Box>
                            <Typography gutterBottom><strong>{ltv_details['Verdi av sikkerhet'].caption}:</strong></Typography>
                            <Tooltip title={ltv_details['Verdi av sikkerhet'].caption} arrow>
                                <Chip 
                                    label={formatCurrency(Number(ltv_details['Verdi av sikkerhet'].value))}
                                    sx={{ ...getColorStyle(ltv_details['Verdi av sikkerhet'].color) }}
                                />
                            </Tooltip>
                        </Box>
                        <Box>
                            <Typography gutterBottom><strong>{ltv_details['LTV'].caption}:</strong></Typography>
                            <Tooltip title={ltv_details['LTV'].caption} arrow>
                                <Chip 
                                    label={formatPercentage(Number(ltv_details['LTV'].value))}
                                    sx={{ ...getColorStyle(ltv_details['LTV'].color) }}
                                />
                            </Tooltip>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
); 