import { useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Snackbar,
    Alert,
} from '@mui/material';
import { useNordaxActions } from '../useNordaxActions';

interface NordaxData {
    status: string;
    bankName: string;
    message: string;
    data: {
        uuid: string;
        nordaxId: string;
        status: string;
    };
}

interface Props {
    data: NordaxData | null;
    onActionComplete?: () => void;
}

export const NordaxOffers = ({ data, onActionComplete }: Props) => {
    const [confirmRejectOpen, setConfirmRejectOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    const { acceptOffer, rejectOffer } = useNordaxActions();

    if (!data?.data) {
        return null;
    }

    const { uuid, status } = data.data;

    const handleAcceptOffer = async () => {
        try {
            await acceptOffer.mutateAsync({ uuid });
            setSuccessMessage('Offer accepted successfully');
            onActionComplete?.();
            // Reload page after 2 seconds
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } catch (error) {
            console.error('Error accepting offer:', error);
        }
    };

    const handleRejectOffer = async () => {
        try {
            await rejectOffer.mutateAsync({ uuid });
            setConfirmRejectOpen(false);
            setSuccessMessage('Offer rejected successfully');
            onActionComplete?.();
            // Reload page after 2 seconds
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } catch (error) {
            console.error('Error rejecting offer:', error);
        }
    };

    return (
        <>
            <Card sx={{ mt: 2 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Tilbud fra Nordax
                    </Typography>
                    <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                        {status === 'Approved' && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAcceptOffer}
                                disabled={acceptOffer.isLoading || rejectOffer.isLoading}
                            >
                                Aksepter
                            </Button>
                        )}
                        {!['Cancelled', 'Rejected'].includes(status) && (
                            <Button
                                variant="outlined"
                                color="error"
                            onClick={() => setConfirmRejectOpen(true)}
                            disabled={acceptOffer.isLoading || rejectOffer.isLoading}
                        >
                                Avslå
                            </Button>
                        )}
                    </Box>
                </CardContent>
            </Card>

            {/* Confirmation Dialog */}
            <Dialog open={confirmRejectOpen} onClose={() => setConfirmRejectOpen(false)}>
                <DialogTitle>Bekreft avslag</DialogTitle>
                <DialogContent>
                    Vil du avslå tilbudet fra Nordax?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmRejectOpen(false)}>Avbryt</Button>
                    <Button onClick={handleRejectOffer} color="error" autoFocus>
                        Bekreft avslag
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Success Message */}
            <Snackbar
                open={!!successMessage}
                autoHideDuration={2000}
                onClose={() => setSuccessMessage(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert 
                    onClose={() => setSuccessMessage(null)} 
                    severity="success"
                    sx={{ width: '100%' }}
                >
                    {successMessage}
                </Alert>
            </Snackbar>
        </>
    );
}; 